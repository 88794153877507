import React from "react";
import "/VSCODE_DEVELOPING/React/my_chat_app/src/css/header.css"


export default function Header() {
  return (
    <div className="header">
      <h1 className="heading">Chat Aplikacija v1.0</h1>
    </div>
  );
}
import React from "react";
import "/VSCODE_DEVELOPING/React/my_chat_app/src/css/headerLogin.css"


export default function HeaderLogin() {
  return (
    <div className="header">
      <h1 className="heading">LOGIN</h1>
    </div>
  );
}